import {
  filterCategoryNames,
  PRODUCT_TYPE_PARAM,
} from '@/config/filter';
import { State } from '@/config/state';
import type {
  FilterCategoryName,
  FilterCategoryWithFacets,
} from '@/types/list-page';

const usePreselectedFilters = () => {
  const route = useRoute();

  const preselectedFilters = useState<FilterCategoryWithFacets>(State.PreselectedFilters);
  const areInitialFiltersSetViaSearchParams = useState<boolean>(State.ArePreselectedFiltersCustomized, () => true);

  const getAreInitialFiltersSetViaSearchParams = computed(() => areInitialFiltersSetViaSearchParams.value);

  const getPreselectedFilters = computed(() => preselectedFilters.value);

  const setPreselectedFilters = (filters?: FilterCategoryWithFacets) => {
    const { query } = route;

    const clonedQuery: any = { ...query };

    const shouldUseSearchParams = searchParamsIncludeFilter(clonedQuery);

    if (!shouldUseSearchParams && filters) {
      preselectedFilters.value = filters;
      areInitialFiltersSetViaSearchParams.value = false;

      return;
    }

    const hasSearchProductType = PRODUCT_TYPE_PARAM in query;
    if (hasSearchProductType) {
      if (clonedQuery.product) {
        if (typeof clonedQuery.product === 'string') {
          clonedQuery.product = [
            clonedQuery[PRODUCT_TYPE_PARAM],
            clonedQuery.product,
          ];
        } else if (Array.isArray(clonedQuery.product)) {
          clonedQuery.product.unshift(clonedQuery[PRODUCT_TYPE_PARAM]);
        }
      } else {
        clonedQuery.product = clonedQuery[PRODUCT_TYPE_PARAM];
      }
    }

    const filteredQuery = Object.keys(clonedQuery)
      .filter((key): key is FilterCategoryName => filterCategoryNames.includes(key as FilterCategoryName))
      .reduce((acc, key) => {
        const filteredValues = ensureValuesAreArrays({ [key]: clonedQuery[key] });
        acc[key] = filteredValues[key];
        return acc;
      }, {} as FilterCategoryWithFacets);

    preselectedFilters.value = filteredQuery;
  };

  return {
    getAreInitialFiltersSetViaSearchParams,
    getPreselectedFilters,
    setPreselectedFilters,
  };
};

export { usePreselectedFilters };
