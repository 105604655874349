<script setup lang="ts">
import { isEqual } from 'lodash-es';
import {
  arrayWithUniqueValues,
  getDurationInSeconds,
  Logger,
  sortedFilterFacetGroups,
} from 'utils';
import { DesignType } from '#gql/default';
import {
  HITS_PER_PAGE,
  type SortingIndex,
} from '@/config/designs';
import type { DesignsQuery } from '#gql/default';
import type { AlgoliaFilterFacetGroup } from '@/graphql-bff';
import type { FilterCategoryWithFacets } from '@/types/list-page';

withDefaults(defineProps<{
  filterCategories?: FilterCategoryWithFacets;
  preselectedDesignType?: DesignType;
  preselectedProductColor?: string;
  preselectedSorting?: SortingIndex | '';
  searchParam?: string;
  shopSlug?: string;
}>(), { preselectedDesignType: '',preselectedProductColor: '',searchParam: '', })

const GqlInstance = useGql();

const {
  getClientCacheItem,
  setClientCacheItem,
} = useClientCache();

const {
  getProductColorFilterOptions,
  setActiveProductColorFilterOption,
} = useProductColorFilter();

const { setFilterScaffold } = useFilterScaffold();

const { initCommunicationBar } = useCommunicationBar();

const {
  areAllFiltersEmpty,
  getActiveFilters,
  getCurrentFilterGroups,
  getFilterFacetsForListingPage,
  getIsFiltersListHiddenMobile,
  getSelectedDesignType,
  resetFiltersSortedByInitialSelection,
  setActiveFilters,
  setCurrentFilterGroups,
  setSelectedDesignType,
  updateCurrentFilterGroupsAfterFilterWasToggled,
} = useActiveFilters();

const {
  getPreselectedFilters,
  setPreselectedFilters,
} = usePreselectedFilters();

const { currentPage } = usePagination();

const {
  getSorting,
  initSorting,
} = useSortingParameter();

const { locale } = useI18n();

// make sure the sorting is initialized before defining the active index
initSorting(__props.preselectedSorting);

// preselect product color from storyblok config
if (__props.preselectedProductColor) {
  const productColorFilterOption = getProductColorFilterOptions.value
    .find((option) => option.key === __props.preselectedProductColor);
  if (productColorFilterOption) {
    setActiveProductColorFilterOption(productColorFilterOption);
  }
}

resetFiltersSortedByInitialSelection();
await setFilterScaffold();

if (__props.preselectedDesignType) {
  setSelectedDesignType(__props.preselectedDesignType);
}

setPreselectedFilters(__props.filterCategories);
setActiveFilters(getPreselectedFilters.value);
updateCurrentFilterGroupsAfterFilterWasToggled(true, true);

const {
  data,
  execute,
  status,
} = await useAsyncData(
  'designs',
  () => {
    const filterFacets = getFilterFacetsForListingPage();
    const filterFacetsWithNonEmptyValues = filterFacets.filter((facet) => facet.value.length > 0);
    const facetsString = sortedFilterFacetGroups(filterFacetsWithNonEmptyValues).map((facet) => `${facet.key}_${facet.value.map((facetValue) => `${facetValue.key}=${facetValue.value.join(',')}`).join('_')}`)
      .join('_');
    const cacheKey = `designs-${locale.value};type:${getSelectedDesignType.value ?? 'ALL'};sorting:${getSorting.value};filter:${facetsString};shop:${__props.shopSlug};page:${currentPage.value}`;

    const shouldCache = !__props.searchParam && getSorting.value !== 'latest';
    const clientCacheData = getClientCacheItem<Promise<DesignsQuery['designs']>>(cacheKey);
    if (shouldCache && clientCacheData) {
      return clientCacheData;
    }

    const getDesigns = async () => {
      try {
        const response = await GqlInstance('Designs', {
          designType: getSelectedDesignType.value,
          filterFacetGroups: filterFacetsWithNonEmptyValues,
          localeCode: locale.value,
          nodesPerPage: HITS_PER_PAGE,
          page: currentPage.value - 1,
          query: __props.searchParam,
          shopSlug: __props.shopSlug,
          sort: getSorting.value,
        });

        if (response.designs && response.designs.totalCount > 0 && shouldCache) {
          setClientCacheItem(cacheKey, response.designs, getDurationInSeconds({ minutes: 15 }));
        }

        return response.designs;
      } catch (error) {
        Logger.error('Error fetching the designs', {
          cacheKey,
          error,
        });

        return {
          facets: {},
          nodes: [],
          totalCount: 0,
        } as DesignsQuery['designs'];
      }
    };

    return getDesigns();
  },
);

const showLoadingOverlay = computed(() => status.value === 'pending'
  && typeof window !== 'undefined');

const unwatch = watch([
  currentPage,
  getSorting,
  getSelectedDesignType,
  getActiveFilters,
  () => __props.searchParam,
], () => {
  execute();
});

watch(
  () => data.value?.facets ?? {},
  (after) => {
    if (after !== undefined && !isEmpty(after)) {
      const newFilterGroups = createFilterWithEnabledStateForFacets(after, getCurrentFilterGroups.value);
      setCurrentFilterGroups(newFilterGroups);
    }
  },
  { immediate: true },
);

watch(
  () => getFilterFacetsForListingPage(),
  async (after, before) => {
    const getProductFilter = (filterGroups: AlgoliaFilterFacetGroup[]) => {
      const productFilterFacet = filterGroups?.find((facet) => facet.key === 'product')?.value;
      const productFilter = productFilterFacet?.flatMap((facet) => {
        const filter: string[] = facet.value ?? [];

        return filter;
      }) ?? [];

      return arrayWithUniqueValues(productFilter);
    };

    const afterProductFilter = getProductFilter(after);
    const beforeProductFilter = getProductFilter(before);
    if (afterProductFilter && beforeProductFilter && !isEqual(afterProductFilter, beforeProductFilter)) {
      await initCommunicationBar({
        page: 'listing-page',
        productFilter: afterProductFilter,
      });
    }
  },
  { immediate: false },
);

onBeforeRouteLeave(() => {
  unwatch();
  setSelectedDesignType(DesignType.ALL);
});
</script>

<template>
  <div>
    <div
      id="design-listing-component"
      class="design-listing-component !z-0 pb-4 pt-2 md:grid md:p-0 md:pt-2"
      data-cy="design-listing-component"
    >
      <div
        class="filter-button-bar-wrapper sticky top-0 z-50 mb-2 bg-white px-4 md:flex md:p-0"
        :class="{'pb-2': areAllFiltersEmpty}"
      >
        <div class="filter-button-wrapper relative pt-2 md:my-3 md:flex md:items-center md:pl-5 md:pt-0 lg:pl-10 2xl:pl-0">
          <FilterButton />
          <div class="inset-y-1/5 absolute right-0 hidden h-3/5 w-1 border-r border-grey-mid md:block"></div>
        </div>
        <SelectedFilterBar class="bg-white py-2 md:w-full md:min-w-0 md:px-[18px] md:py-1 lg:pr-10 2xl:pr-0" />
      </div>
      <ResultContainer
        v-if="data?.nodes"
        :designs="data?.nodes"
        :total-count="data?.totalCount"
      />
    </div>
    <FilterGroupsOverlay v-if="!getIsFiltersListHiddenMobile" />
    <LoadingOverlay
      should-cover-entire-page
      :is-loading="showLoadingOverlay"
    />
  </div>
</template>

<style lang="scss" scoped>
$filter-bar-width: 290px;
$padding-big: 20px;
$padding-left-right-desktop: 40px;
$padding-small: 15px;

@include bp-desktop {
  .design-listing-component {
    grid: min-content min-content minmax(0, 1fr) / minmax($filter-bar-width, calc($filter-bar-width + ((100vw - 1710px) / 2) + $padding-left-right-desktop)) minmax(0, 1fr);
    grid-template-areas:
      'filterButtons filterButtons'
      'results results'
  }

  .filter-button-bar-wrapper {
    grid-area: filterButtons;
  }

  .filter-button-wrapper {
    flex: $filter-bar-width 0 0;
  }
}

@include bp-1700 {
  $padding-extra: calc(((100vw - 1710px) / 2) + $padding-left-right-desktop);

  .filter-button-bar-wrapper {
    padding: 0 $padding-extra;
  }
}
</style>
